import { createContext, PropsWithChildren, useContext } from "react";

interface ICarouselContext {
  clearCurrentImageIndexByVinCache: () => void;
  getCurrentImageIndexByVin: (data: string) => number;
  setCurrentImageIndexForVin: (data: ICarouselImageByVin) => void;
}

interface ICarouselImageByVin {
  vin: string;
  currentImageIndex: number;
}

const CarouselContext = createContext<ICarouselContext | undefined>(undefined);

export const CarouselContextProvider = (props: PropsWithChildren) => {
  let cacheCarouselImageByVin: ICarouselImageByVin[] = [];

  const setCurrentImageIndexForVin = (data: ICarouselImageByVin) => {
    const carouselImageByVin = cacheCarouselImageByVin.find(
      (value: ICarouselImageByVin) => value.vin === data.vin
    );
    if (carouselImageByVin) {
      carouselImageByVin.currentImageIndex = data.currentImageIndex;
    } else {
      cacheCarouselImageByVin.push(data);
    }
  };

  const getCurrentImageIndexByVin = (vin: string) => {
    const carouselImageByVin = cacheCarouselImageByVin.find(
      (value: ICarouselImageByVin) => value.vin === vin
    );
    if (carouselImageByVin) {
      return carouselImageByVin.currentImageIndex;
    } else {
      return 0;
    }
  };

  const clearCurrentImageIndexByVinCache = () => {
    cacheCarouselImageByVin = [];
  };

  const context: ICarouselContext = {
    clearCurrentImageIndexByVinCache,
    getCurrentImageIndexByVin,
    setCurrentImageIndexForVin,
  };

  return <CarouselContext.Provider value={context}>{props.children}</CarouselContext.Provider>;
};

export default CarouselContext;

// Create a custom hook for using the context
export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error("useCarouselContext must be used within an CarouselContextProvider");
  }
  return context;
};
