import propTypes from "prop-types";

/**
 * Close Menu icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const CloseMenuIcon = ({ className = "" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <g id="close">
        <path
          id="Vector"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="#737373"
        />
      </g>
    </svg>
  );
};

CloseMenuIcon.propTypes = {
  className: propTypes.string,
};

export default CloseMenuIcon;
