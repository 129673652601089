import propTypes from "prop-types";

/**
 * TargetIcon icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const TargetIcon = ({ className = "" }) => {
  return (
    <svg width="24" height="24" className={`${className}`}>
      <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2h-2.06ZM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7Z" />
    </svg>
  );
};

TargetIcon.propTypes = {
  className: propTypes.string,
};

export default TargetIcon;
