import {
  CarFilterKey,
  CarFilters,
  FilterKey,
  Filters,
  IMakeModels,
  NavigationFilterKey,
  NavigationFilters,
} from "types/filters";

import { IConfiguration } from "types/session";

import { decodeMapper } from "./decoders";

export enum ECarFilterKey {
  ismotortrendcertified = "ismotortrendcertified",
  hasprice = "hasprice",
  minprice = "minprice",
  maxprice = "maxprice",
  minpayment = "minpayment",
  maxpayment = "maxpayment",
  maxmileage = "maxmileage",
  distance = "distance",
  maxyear = "maxyear",
  minyear = "minyear",
  numyears = "numyears",
  makesmodels = "makesmodels",
  bodytypes = "bodytypes",
  exteriorcolors = "exteriorcolors",
  interiorcolors = "interiorcolors",
  transmission = "transmission",
  drivetrain = "drivetrain",
  carfax = "carfax",
  carfaxvalue = "carfaxvalue",
  fueltype = "fueltype",
  condition = "condition",
}

export const defaultCarFilters: CarFilters = {
  ismotortrendcertified: false,
  hasprice: false,
  minprice: 0,
  maxprice: 500 * 1000,
  maxmileage: 1000 * 1000,
  distance: 50,
  maxyear: new Date().getFullYear() + 1,
  minyear: undefined,
  makesmodels: [],
  bodytypes: [],
  exteriorcolors: [],
  interiorcolors: [],
  transmission: [],
  drivetrain: [],
  carfax: [],
  carfaxvalue: [],
  fueltype: [],
  condition: [],
  minpayment: 0,
  maxpayment: 1500,
  tradein: 0,
  creditscore: "PRIME",
  term: 72,
  downpercent: 20,
  downamount: 0,
};

export const defaultNavigationFilters: NavigationFilters = {
  zip: null,
  page: 1,
  itemsperpage: 24,
  sortorder: "featured",
};

export const defaultFilters: Filters = {
  ...defaultCarFilters,
  ...defaultNavigationFilters,
};

export const carFilterKeys: CarFilterKey[] = [
  "ismotortrendcertified",
  "hasprice",
  "minprice",
  "maxprice",
  "minpayment",
  "maxpayment",
  "maxmileage",
  "distance",
  "maxyear",
  "minyear",
  "makesmodels",
  "bodytypes",
  "exteriorcolors",
  "interiorcolors",
  "transmission",
  "drivetrain",
  "carfax",
  "carfaxvalue",
  "fueltype",
  "condition",
];

export const navigationFilterKeys: NavigationFilterKey[] = [
  "page",
  "zip",
  "itemsperpage",
  "sortorder",
];

export const filterKeys: FilterKey[] = [...carFilterKeys, ...navigationFilterKeys];

const encodeMakesModels = (makesModels: IMakeModels[]) => {
  const arr = [];
  for (const car of makesModels) {
    let str = "";
    str += car.make;
    const modelsStr = car.models.join(".");
    if (modelsStr) {
      str += ".";
      str += modelsStr;
    }
    arr.push(str);
  }
  return arr.join(",");
};

export const encodeFilterKey = (filterKey: FilterKey, val: unknown) => {
  switch (filterKey) {
    case "makesmodels":
      return { key: "makesmodels", val: encodeMakesModels(val as IMakeModels[]) };
    default:
      return { key: filterKey.toLowerCase(), val: `${val}` };
  }
};

export const deserializeUrl = (urlString: string): Filters => {
  const filters = { ...defaultFilters };
  const params = new URLSearchParams(urlString);

  for (const kv of params) {
    const key = kv[0] as FilterKey;
    const stringValue = kv[1];

    const valueFromUrl = decodeMapper(key)(stringValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters[key] = valueFromUrl;
  }

  return filters;
};

export const MIN_MAX_YEARS_RANGE = 20;

export const DEFAULT_MAX_MILEAGE = 1000000;

export const DEFAULT_MAX_PRICE = 500000;

type ConfigKey = keyof Pick<IConfiguration, "motortrendBranding" | "isCarfaxEnabled">;

const configToFilterKeysMap: Record<ConfigKey, ECarFilterKey | ECarFilterKey[]> = {
  motortrendBranding: ECarFilterKey.ismotortrendcertified,
  isCarfaxEnabled: [ECarFilterKey.carfax, ECarFilterKey.carfaxvalue],
};

export const getDisabledFilterKeys = (config: IConfiguration) => {
  const keysToDelete: (ECarFilterKey | ECarFilterKey[])[] = [];
  const configKeysToCheck: ConfigKey[] = ["motortrendBranding", "isCarfaxEnabled"];
  for (const key of configKeysToCheck) {
    if (config[key] === false) {
      keysToDelete.push(configToFilterKeysMap[key]);
    }
  }
  return keysToDelete.flat();
};
