import konsole from "./konsole";
// See: https://stackoverflow.com/questions/57813144/how-to-select-element-inside-open-shadow-dom-from-document
export const querySelectorAll = (node, selector) => {
  const nodes = [...node.querySelectorAll(selector)],
    nodeIterator = document.createNodeIterator(node, Node.ELEMENT_NODE);
  let currentNode;
  while ((currentNode = nodeIterator.nextNode())) {
    if (currentNode.shadowRoot) {
      nodes.push(...querySelectorAll(currentNode.shadowRoot, selector));
    }
  }
  return nodes;
};

export const escapeId = (id) => {
  const escapedId = "#" + id.replaceAll(":", "\\:");
  return escapedId;
};

Document.prototype.getElementByIdShadowPiercing = function (id) {
  const selectedElement = document.getElementById(id);
  if (selectedElement) {
    return selectedElement;
  } else {
    const escapedId = escapeId(id);
    const nodes = querySelectorAll(document, escapedId);
    if (nodes.length > 1) {
      konsole.error(`The query selector "${escapedId}" (${id}) found more than one node.`);
    }
    if (nodes.length > 0) {
      return nodes[0];
    }
  }
  return null;
};
