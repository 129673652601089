import clsx from "clsx";
import { useLocation } from "react-router-dom";

import FooterLinks from "components/footer-links";

import { useAppContext } from "store/app-context";

const Footer = () => {
  const { configuration: { applicationCopyright = "" } = {} } = useAppContext();

  const updatedCopyright = applicationCopyright
    .replace("{{Domain}}", window.location.host)
    .replace("{{CurrentYear}}", new Date().getFullYear().toString());

  const location = useLocation();
  const { pathname } = location;

  const isSearchResultsPage = pathname.includes("/search-results");

  return (
    <div className={clsx({ "xl:border-t border-neutral-300": isSearchResultsPage })}>
      <footer className="text-xs p-4 mt-auto w-full px-6 m-auto text-neutral-700 max-w-screen-xl mx-auto text-center">
        {updatedCopyright}
        <FooterLinks />
      </footer>
    </div>
  );
};

export default Footer;
