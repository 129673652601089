import React, { useEffect } from "react";

import parse, { DOMNode } from "html-react-parser";

interface ScriptLoaderProps {
  script: string;
  id: string;
}

// Helper function to convert a string to a boolean value.
// Treat empty string as true.  This allows for the async attribute to be set to true by default.
const getBooleanValue = (value: string | undefined) => {
  if (value === undefined) {
    return false;
  }
  if (typeof value === "string" && (value === "" || value.toLowerCase() === "true")) {
    return true;
  }
  return false;
};

/**
 * Functional component used to load a script string into the head of the document.
 * An id is used to ensure that only one instance of the script is loaded.
 *
 * A script with a src='...' attribute will be loaded as an external script.
 * A script with no src attribute will be loaded as an inline script.
 *
 * Examples:
 *
 * <ScriptLoader script="<script>console.log(`Script was run.`);</script>" id="dog" />
 *
 * <ScriptLoader script="<script src='https://ftlaunchpad.ai/lptm.js?id=e8f868' async></script>" id="tenantID"/>
 *
 * @param param0
 * @returns
 */
const ScriptLoader: React.FC<ScriptLoaderProps> = ({ script, id }) => {
  useEffect(() => {
    // Only allow one instance of the script to be loaded.
    const _script = document.head.querySelectorAll(`script[id="${id}"]`);
    if (!script || _script?.length > 0) {
      return;
    }

    parse(script, {
      replace: (domNode: DOMNode) => {
        if (domNode.type === "script") {
          const script: HTMLScriptElement = document.createElement("script");

          if (domNode.attribs?.src) {
            script.src = domNode.attribs.src;
            script.async = getBooleanValue(domNode.attribs.async);
            script.id = id;
          }

          if (domNode.children && domNode.children.length > 0) {
            const firstChild = domNode.children[0];
            if (firstChild.type === "text") {
              script.innerHTML = firstChild.data;
              script.id = id;
            } else {
              console.warn("ScriptLoader: Invalid script string.");
            }
          }

          if (script.id) {
            document.head.appendChild(script);
          }
          return () => {
            if (script.id) {
              document.head.removeChild(script);
            }
          };
        }
      },
    });
  }, [script]);

  return null;
};

export default ScriptLoader;
