import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";

import { TRACKING_EVENTS } from "components/tracking-provider/tracking-provider";

import Button from "../button/button";
import ReplayIcon from "../icons/replay-icon";

import UTurnIcon from "../icons/u-turn-icon";

interface IGenericError {
  title?: string;
  subTitle?: string;
}

const GenericError = (props: IGenericError) => {
  const {
    title = "Oops, Something Went Wrong",
    subTitle = (
      <>
        The page you&apos;re looking for is temporarily unavailable. Please try your search again.
      </>
    ),
  } = props;

  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent({
      action: TRACKING_EVENTS.ERROR_VIEW,
      error_location: window.location.href,
      page_title: "Error",
    });
  }, []);

  return (
    <>
      <div className="w-full max-w-md m-auto text-center p-6">
        <UTurnIcon className={"mx-auto mb-3"} />
        <h1 className="mb-3 text-2xl font-bold">{title}</h1>
        <p className="mb-3">{subTitle}</p>
        <div className="flex justify-center">
          <Button
            icon={
              <>
                <ReplayIcon className="fill-primary-600" />
              </>
            }
            iconPosition="before"
            onClick={() => {
              navigate("/");
            }}
            text="Refresh"
            variant="secondary"
          />
        </div>
      </div>
    </>
  );
};

export default GenericError;
