import { createContext, useState } from "react";

import { EState } from "components/toast";

const ToastContext = createContext({
  showToast: true | false,
  setShowToast: function (_a) {
    //do nothing
  },
  toastEnum: 0,
  setToastEnum: function (_a) {
    //do nothing
  },
  toastMsg: "",
  setToastMsg: (_a) => {
    //do nothing
  },
  delay: 3000,
  setDelay: (_a) => {
    //do nothing
  },
});

export function ToastContextProvider(props) {
  const [showToast, setShowToast] = useState(false);
  const [toastEnum, setToastEnum] = useState(EState.WARN);
  const [toastMsg, setToastMsg] = useState("");
  const [delay, setDelay] = useState(3000);

  const context = {
    showToast,
    setShowToast,
    toastEnum,
    setToastEnum,
    toastMsg,
    setToastMsg,
    delay,
    setDelay,
  };

  return <ToastContext.Provider value={context}>{props.children}</ToastContext.Provider>;
}

export default ToastContext;
