import { BodyType, CreditScore, TermLength, VehicleSortOrder } from "../../types/filters";

export const bodyTypeOptions: BodyType[] = [
  "Convertible",
  "Coupe",
  "Hatchback",
  "Pickup",
  "Sedan",
  "SUV/Crossover",
  "Van/Minivan",
  "Wagon",
];

export const carfaxHistoryOptions = ["NoAccidentOrDamage", "One", "Personal"];

export const carfaxValueOptions = ["Great", "Good", "Fair"];

export const baseColorOptions = ["Beige", "Black", "Brown", "Gray", "Red", "White"];

export const exteriorColorOptions = [
  ...baseColorOptions,
  "Blue",
  "Green",
  "Orange",
  "Silver",
  "Yellow",
];

export const vehicleConditionOptions = ["New", "Used"];

export const interiorColorOptions = [...baseColorOptions, "Tan"];

export const creditScoreOptions: CreditScore[] = [
  "DEEP_SUBPRIME",
  "NONPRIME",
  "PRIME",
  "SUBPRIME",
  "SUPER_PRIME",
];

export const fuelTypeOptions = [
  "BIO_DIESEL",
  "DIESEL",
  "ELECTRIC",
  "FLEX_FUEL",
  "GAS",
  "HYBRID",
  "HYDROGEN",
  "NATURAL_GAS",
  "PLUGIN_HYBRID",
  "PROPANE",
];

export type DriveTrainOption = "4WD" | "4X2" | "AWD" | "FWD" | "RWD";

export const driveTrainOptions: DriveTrainOption[] = ["4WD", "4X2", "AWD", "FWD", "RWD"];

export const transmissionOptions = ["AUTOMATIC_OR_CVT", "MANUAL"];

export const distanceRangeOptions = ["10", "25", "50", "75", "100+"];

export const mileageConversionOptions = [
  1000, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 15, 10, 9, 8, 7, 6, 5, 4, 3,
  2, 1,
];

export const priceConversionOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50,
  60, 70, 80, 90, 100, 150, 200, 250, 500,
];

export const MAX_PAYMENT_OPTION_VALUE = 1500;

export const createPaymentOptionsIncrements = (): number[] => {
  const output: number[] = [50, 100, 150, 200];
  let lastPrice = output[output.length - 1];

  while (lastPrice !== MAX_PAYMENT_OPTION_VALUE) {
    lastPrice = output[output.length - 1] + 25;
    output.push(lastPrice);
  }

  return output;
};

export const monthlyPaymentOptions = createPaymentOptionsIncrements();

export const termOptions: TermLength[] = [36, 48, 60, 72];

export const PRICE_DEFAULTS = {
  MIN_PRICE: 0,
  MAX_PRICE: 500000,
};

export const PAYMENT_DEFAULTS = {
  MIN_PAYMENT: 50,
  MAX_PAYMENT: 1500,
  DOWN_AMOUNT: 0,
  DOWN_PERCENT: 20,
  TERM: 72 as TermLength,
  TRADE_IN: 0,
  CREDIT_SCORE: "PRIME" as CreditScore,
};

export const sortOptions: VehicleSortOrder[] = [
  "featured",
  "distAsc",
  "priceAsc",
  "priceDesc",
  "mileageAsc",
  "mileageDesc",
  "yearAsc",
  "yearDesc",
];
