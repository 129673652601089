import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAppContext } from "store/app-context";

import AutoFinderLogo from "./icons/autofinder/auto-finder-logo";
import URLMenu from "./url-menu";

const Logo = () => {
  const {
    errorBoundary,
    setErrorBoundary,
    configuration: { logoUrl = "", externalLinks = [] } = {},
  } = useAppContext();
  const [useExternalLogoUrl, setUseExternalUrlLogo] = useState<boolean>(!!logoUrl);
  const [externalLogoLoaded, setExternalLogoLoaded] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNavigateToHome = () => {
    if (errorBoundary) {
      setErrorBoundary(false);
    }
    if (window.location.pathname === "/") {
      window.scrollTo(0, 0);
    } else {
      navigate("/");
    }
    navigate("/");
  };

  const handleOnErrorLoadingExernalLogoUrl = () => {
    console.warn("Unable to load external logo: ", logoUrl);
    setUseExternalUrlLogo(false);
  };

  return (
    <div className={"flex py-3 pl-4"}>
      {(!useExternalLogoUrl || externalLogoLoaded) && !!externalLinks.length && (
        <URLMenu links={externalLinks} />
      )}
      {useExternalLogoUrl ? (
        <img
          src={logoUrl}
          className={"w-[240px] h-9 cursor-pointer object-contain object-left"}
          onClick={handleNavigateToHome}
          onError={handleOnErrorLoadingExernalLogoUrl}
          onLoad={() => setExternalLogoLoaded(true)}
          alt={"Logo"}
        />
      ) : (
        <AutoFinderLogo className={"w-[240px] h-9 cursor-pointer object-contain object-left"} />
      )}
    </div>
  );
};

export default Logo;
