import { useEffect } from "react";

import { useAppContext } from "store/app-context";

const FaviconUpdater = () => {
  const { configuration } = useAppContext();

  useEffect(() => {
    const faviconLink = document.querySelector('link[rel="icon"]');
    if (faviconLink) {
      document.head.removeChild(faviconLink);
    }

    if (configuration?.faviconUrl) {
      const newFaviconLink = document.createElement("link");
      newFaviconLink.rel = "icon";
      newFaviconLink.href = configuration.faviconUrl;
      document.head.appendChild(newFaviconLink);
    }
  }, [configuration]);

  return null;
};

export default FaviconUpdater;
