// TODO: Consider passing in timeout and cleanup params via an associative array.
// TODO: There is also a toolchain to auto-generate HTTP clients from OpenAPI specs.
//       That would eliminate the need for both this and the api-calls.js file.

// for what it's worth we almost never use DELETE or PUT here.
export const deleteData = async (url = "", timeout = 0) => {
  return fetchData("DELETE", url, timeout);
};

export const putData = async (url = "", data = {}, timeout = 0) => {
  return fetchData("PUT", url, data, timeout);
};

export const getData = async (url = "", timeout = 0) => {
  return fetchData("GET", url, {}, timeout);
};

export const postData = (url = "", data = {}, timeout = 0) => {
  return fetchData("POST", url, data, timeout);
};

const fetchData = async (method, url = "", data = {}, timeout = 0) => {
  const params = {
    method,
    headers: {
      accept: "text/json",
      "Content-Type": typeof data === "string" ? "text/plain" : "text/json",
    },
  };

  if (method.toUpperCase() !== "GET") {
    if (typeof data === "string") {
      params.body = data;
    } else {
      params.body = JSON.stringify(data);
    }
  }

  let controller = undefined;
  let timeoutId = undefined;

  if (timeout > 0) {
    controller = new AbortController();
    params.signal = controller.signal;

    timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);
  }

  let retVal = {};
  let statusCode;
  let response;

  try {
    response = await fetch(url, params);
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
    statusCode = response.status;
    if (response.ok) {
      retVal.success = true;
      // Happy Path
      retVal.data = await response.json();
    } else {
      // Server reported an error
      retVal.error = {};
      retVal.error.code = statusCode;
      retVal.error.message = statusCode + (response.statusText ? ` - ${response.statusText}` : "");
    }
  } catch (error) {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
    // Something unexpected happened
    retVal.error = {};
    if (error instanceof DOMException) {
      // The fetch call timed out via the AbortSignal.
      retVal.error = {};
      retVal.error.code = error.name;
      retVal.error.message = error.name + (error.message ? ` - ${error.message}` : "");
    } else {
      // Usually a Syntax error for cases where server returns 200/ok along with a response body which can't be converted to json.
      retVal.error.code = statusCode;
      retVal.error.message = statusCode + (error.message ? ` - ${error.message}` : "");
    }
  }
  return retVal;
};
