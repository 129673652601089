import clsx from "clsx";
import PropTypes from "prop-types";

import Loading from "components/loading";

/**
 * Standard button component
 *
 * @param {Object} [props.fullWidth] - fullWidth is optional and set to false by default
 * @param {Object} [props.icon] - icon is optional and not set by default
 * @param {string} [props.iconPosition] - iconPosition is optional and not set by default
 * @param {boolean} [props.loading] - if true, display loading animation instead of button text & icon
 * @param {function} [props.onClick] - onClick is a required function and not set by default
 * @param {string} [props.text] - text is optional and not set by default
 * @param {string} [props.type = "button"] - type is optional and defaults to "button"
 * @param {string} [props.variant = "primary"] - variant is optional and not set by default
 * @param {string} [props.className] - className is optional and not set by default
 * @param {string} [props.size] - button size, one of sm, md, lg. Default is md
 *
 */

// TODO: The button class was developed while UX design was in flux.  This has led to an unnecessarily large number of variants.
// When the UX settles down we should revisit this component and see if we can reduce the number of variants.
const Button = ({
  fullWidth = false,
  icon,
  iconPosition,
  loading = false,
  afterIcon = <></>,
  onClick,
  text,
  disabled = false,
  type = "button",
  variant = "primary",
  className = "",
  size = "auto",
  ariaLabel = "",
}) => {
  const variantClasses = {
    primary:
      "text-white font-bold rounded px-4 py-3 flex justify-center items-center gap-2 drop-shadow-md" +
      (!loading &&
        " bg-primary-600 hover:bg-primary-700 active:bg-primary-800 disabled:bg-neutral-300 disabled:hover:bg-neutral-300 disabled:active:bg-neutral-300"),
    secondary:
      "text-primary-600 font-bold bg-white border border-primary-600 rounded px-3 py-2 flex justify-center items-center gap-2 hover:bg-primary-50 active:bg-primary-50 disabled:bg-neutral-300  disabled:hover:bg-neutral-300 disabled:active:bg-neutral-300",
    tertiary:
      "text-primary-600 font-bold bg-white rounded px-4 py-3 flex justify-center items-center gap-2 hover:bg-primary-50 active:bg-primary-50 disabled:bg-neutral-300 disabled:hover:bg-neutral-300 disabled:active:bg-neutral-300",
    quaternary:
      "text-primary-600 bg-transparent rounded flex justify-center items-center gap-2 disabled:bg-neutral-300 disabled:hover:bg-neutral-300 disabled:active:bg-neutral-300",
    quinary:
      "text-primary-600 font-bold bg-white border border-primary-600 rounded px-3 py-2 flex justify-center items-center gap-2 hover:bg-primary-50 active:bg-primary-50 disabled:border-neutral-300 disabled:text-neutral-500 disabled:bg-neutral-100 disabled:hover:bg-neutral-100 disabled:active:bg-neutral-100",
    senary:
      "text-primary-600 font-bold bg-white border border-neutral-300 rounded px-[7px] py-[7px] flex justify-center items-center gap-2 hover:bg-primary-50 active:bg-primary-50 disabled:border-neutral-300 disabled:text-neutral-500 disabled:bg-neutral-100 disabled:hover:bg-neutral-100 disabled:active:bg-neutral-100",
    unstyled: "",
  };

  const buttonClasses = variantClasses[variant] + (className && " " + className);
  const clazzName =
    buttonClasses + (fullWidth ? " w-full" : "") + " bg-neutral-100 border border-neutral-300";

  const sizing = {
    sm: "h-[40px]",
    md: "h-[50px]",
    lg: "h-[50px]",
    auto: "h-auto",
  };

  return (
    <>
      {loading ? (
        <>
          <button className={clazzName} disabled={true}>
            <Loading forButton={true} />
          </button>
        </>
      ) : (
        <button
          className={clsx(
            buttonClasses,
            fullWidth && "w-full",
            disabled && "bg-neutral-300",
            sizing[size]
          )}
          onClick={onClick}
          type={type}
          disabled={disabled}
          aria-label={ariaLabel || text}
        >
          {iconPosition === "before" && icon}
          {text}
          {iconPosition === "after" && icon}
          {afterIcon}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  afterIcon: PropTypes.object,
  iconPosition: PropTypes.oneOf(["before", "after"]),
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit"]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "quinary",
    "senary",
    "unstyled",
  ]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  ariaLabel: PropTypes.string,
};

export default Button;
