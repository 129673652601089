/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";

import { SeverityLevel } from "@sentry/types";
import ReactGA from "react-ga4";

import TagManager from "react-gtm-module";

import { getConfig } from "lib/config";

import konsole from "./konsole";

const config = getConfig();

interface SentryErrorOptions {
  errorLevel?: SeverityLevel;
  error?: Error;
  message?: string;
  tags?: { [key: string]: string };
  extra?: { [key: string]: any };
}
// Sentry logging
export const sentry = {
  init: () => {
    const {
      SENTRY_DSN: dsn,
      SENTRY_ENVIRONMENT: environment,
      SENTRY_TRACES_SAMPLE_RATE: tracesSampleRate,
      SENTRY_TRACE_PROPAGATION_TARGET_BFF: tracePropagationTargetBff,
    } = config;

    const tracePropagationTargets: (string | RegExp)[] = [new RegExp(tracePropagationTargetBff)];
    // Add localhost to tracePropagationTargets if running locally
    if (window.location.hostname === "localhost") tracePropagationTargets.push("localhost");

    Sentry.init({
      dsn,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control which URLs distributed tracing is enabled on
          tracePropagationTargets,
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: tracesSampleRate || 0.1, // Capture 10% of the transactions by default, but allow lower envs to override
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },

  error({ errorLevel, error, message, tags, extra }: SentryErrorOptions) {
    Sentry.withScope(function (scope) {
      if (tags) {
        Object.entries(tags).forEach(([key, value]) => {
          scope.setTag(key, value);
        });
      }

      if (errorLevel) {
        scope.setLevel(errorLevel);
      }

      error ||= new Error(message || "An error occurred.");
      Sentry.captureException(error, { tags, extra });
    });
  },
};

// Google Tag Manager
const googleTagManager = {
  init: (gtmId?: string) => {
    if (!gtmId) {
      return;
    }
    //this replaces the script block and the iframe block that you see here:
    //https://support.google.com/tagmanager/answer/6103696
    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  },
};

// Console logging
export const consoleProvider = {
  error: ({
    errorLevel,
    error,
    message,
  }: {
    errorLevel: string;
    error: string;
    message: string;
  }) => {
    const {
      //we can swap this out for something specific to console if we want
      SENTRY_ENVIRONMENT: environment,
    } = config;

    if (environment.toLowerCase() !== "production") {
      let log;
      switch (errorLevel) {
        case "error":
          log = konsole.error;
          break;

        case "warning":
          log = konsole.warn;
          break;

        default:
          log = konsole.log;
          break;
      }

      error && log(error);
      message && log(message);
    }
  },
  event: (data: any) => {
    const {
      //we can swap this out for something specific to console if we want or rename the var
      SENTRY_ENVIRONMENT: environment,
    } = config;
    environment.toLowerCase() === "local" && konsole.log(data);
  },
};

// Google analytics logging
export const googleAnalytics = {
  ga4ID: "",
  init: function (ga4ID?: string) {
    if (!ga4ID) {
      return;
    }

    this.ga4ID = ga4ID;

    //GTM is no longer initializing gtag, so doing it in code
    ReactGA.initialize(ga4ID, {
      gaOptions: {
        //the chrome extension will also enable the debug mode when it is enabled
        //debug_mode: false,
        send_page_view: false,
      },
      gtagOptions: {
        //debug_mode: false,
        send_page_view: false,
      },
    });
  },
  event: function (data: any) {
    //the react-ga4 library is just a wrapper around the gtag library
    //the gtag init is coming down from GTM and both the React-GTM-Module and React-GA4 lib use window.dataLayer by default as the ga4 event queue
    data.event_id = Date.now();

    const payload = Object.entries(data).reduce((acc: any, [key, value]) => {
      //can switch action for mp_action, if we decide to use the more precise activities
      if (key === "action" || key === "dimensionData") {
        return acc;
      }

      acc[key] = typeof value === "object" && value !== null ? JSON.stringify(value) : value;
      return acc;
    }, {});

    for (const key in data.dimensionData) {
      const value = data.dimensionData[key];
      if (typeof value === "string") {
        //equivalent to gtag('set', { 'dimension1': '1.23' });
        //https://developers.google.com/tag-platform/gtagjs/configure
        ReactGA.set({ user_properties: { [key]: value } });
      }
    }
    //equivalent to gtag('event', ... });
    //send_to is required here (simply pushing to the dataLayer does not associate the event with the GA4 measurementid or GtmId
    ReactGA.event(data.action, { ...payload, send_to: this.ga4ID });
  },
};

export type GoogleTrackingData = {
  dimensionData: { [key: string]: string | undefined };
  [key: string]: any | undefined;
};

export const initTelemetry = () => {
  sentry.init();
};

export const initTrackingTelemetry = (gaTagManagerId?: string, ga4MeasurementId?: string) => {
  googleAnalytics.init(ga4MeasurementId);

  googleTagManager.init(gaTagManagerId);
};
