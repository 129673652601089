import { useContext, useEffect } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

import { getConfig } from "lib/config";
import konsole from "lib/konsole";

import { getWithExpiry, setWithExpiry } from "lib/local-storage";

import { createActivity, getConfiguration, getOrCreateSession } from "lib/sessions/api-calls";
import AppContext from "store/app-context";

const SESSION_EXPIRY = 86400000; // 24 hours

const EVENT_NAMES = {
  PAGE_VIEW: "page_view",
};

const useSession = () => {
  const { setError, session, setSession, configuration, setConfiguration, metadata, setMetadata } =
    useContext(AppContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const startOrContinueJourney = () =>
    useEffect(() => {
      if (!metadata) {
        _getMetadata();
        return;
      }

      _startOrContinueJourney();
    }, [location.pathname, metadata]);

  const _getMetadata = () => {
    const lowercaseParams = new URLSearchParams();
    for (const [name, value] of searchParams) {
      lowercaseParams.append(name.toLowerCase(), value);
    }

    const metadata = {
      generatorID: lowercaseParams.get("gid"),
      campaignID: lowercaseParams.get("cid"),
      sourceID: lowercaseParams.get("sid"),
      gLeadID: lowercaseParams.get("gleadid"),
      facebookClickID: lowercaseParams.get("fbclid"),
      googleClickID: lowercaseParams.get("gclid"),
      gBraid: lowercaseParams.get("gbraid"),
      wBraid: lowercaseParams.get("wbraid"),
      urlJourneyCode: lowercaseParams.get("jc"),
      feederKind: lowercaseParams.get("feederkind"),
      referralUrl: lowercaseParams.get("referralurl"),
    };
    setMetadata(metadata);
  };

  const _startOrContinueJourney = async () => {
    let currentSession = session;

    try {
      setError(false);

      if (
        // we don't have a session code and journey code in state
        !session.sessionCode ||
        !session.journeyCode ||
        // or the session code and journey code are expired
        getWithExpiry("sessionCode") === null ||
        getWithExpiry("journeyCode") === null
      ) {
        // ask Foundry HQ to continue or start the journey
        currentSession = await _setNewSession();
      }

      await _createActivity(EVENT_NAMES.PAGE_VIEW, currentSession.journeyCode);
      if (!configuration) {
        await _getConfiguration(currentSession.tenantID, currentSession.generatorID);
      }
      _removeWidgetsParams();
    } catch (error) {
      setError(true);
      konsole.error("use-session:_continueJourney:error: ", error);
    }
  };

  const _createActivity = (eventName, journeyCode = undefined) => {
    return createActivity({
      journeyCode: journeyCode || session.journeyCode,
      url: window.location.href,
      eventName,
    });
  };

  const _getConfiguration = async (tenantID, generatorID) => {
    try {
      setError(false);
      const config = await getConfiguration({
        tenantID,
        generatorID,
      });
      konsole.log("_getConfiguration: ", config);
      setConfiguration(config);
    } catch (error) {
      setError(true);
      konsole.error("use-session:_getConfiguration:error: ", error);
    }
  };

  const _setNewSession = async () => {
    const sessionCode = session.sessionCode || getWithExpiry("sessionCode");
    const journeyCode =
      metadata.urlJourneyCode || session.journeyCode || getWithExpiry("journeyCode");

    const config = getConfig();

    try {
      setError(false);
      const newSession = await getOrCreateSession({
        shouldAttemptToContinue: true, // since Marketplace doesn't have a good starting point, always try to continue first
        continueSessionCode: sessionCode,
        continueJourneyCode: journeyCode,
        url: window.location.href,
        generatorID: metadata.generatorID,
        campaignID: metadata.campaignID,
        sourceID: metadata.sourceID,
        gLeadID: metadata.gLeadID,
        facebookClickID: metadata.facebookClickID,
        googleClickID: metadata.googleClickID,
        gBraid: metadata.gBraid,
        wBraid: metadata.wBraid,
        tenantID: config.TENANT_ID, // should only be set in local config
        feederKind: metadata.feederKind,
        referralUrl: metadata.referralUrl,
      });

      setWithExpiry("sessionCode", newSession.sessionCode, SESSION_EXPIRY);
      setWithExpiry("journeyCode", newSession.journeyCode, SESSION_EXPIRY);
      setSession(newSession);
      return newSession;
    } catch (error) {
      setError(true);
      konsole.error("use-session:_setNewSession:error: ", error);
    }
  };

  const _removeWidgetsParams = () => {
    const paramsToDelete = ["jc", "referralurl", "feederkind"];
    let foundKey = false;

    Array.from(searchParams.keys()).forEach((key) => {
      if (paramsToDelete.includes(key.toLowerCase())) {
        foundKey = true;
      }
    });

    if (!foundKey) {
      return;
    }

    setSearchParams((params) => {
      const paramCopy = Array.from(params.keys());

      paramCopy.forEach((key) => {
        if (paramsToDelete.includes(key.toLowerCase())) {
          params.delete(key);
        }
      });

      return params;
    });
  };

  return {
    session,
    configuration,
    startOrContinueJourney,
    createActivity: _createActivity,
  };
};

export default useSession;
