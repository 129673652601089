import { useEffect } from "react";

import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { useTracking } from "react-tracking";

import { TRACKING_EVENTS } from "components/tracking-provider/tracking-provider";

import { useAppContext } from "store/app-context";

import Button from "../button/button";
import ReplayIcon from "../icons/replay-icon";

import UTurnIcon from "../icons/u-turn-icon";

export const ErrorComponent = () => {
  const { resetBoundary } = useErrorBoundary();
  const { errorBoundary, setErrorBoundary } = useAppContext();
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      action: TRACKING_EVENTS.ERROR_VIEW,
      error_location: window.location.href,
      page_title: "Error",
    });
  }, []);

  // Notify the app-context we have encountered an errorBoundary.
  useEffect(() => {
    setErrorBoundary(true);
  }, []);

  // This is the only pace we can reset the error boundary for this wrapper.
  // Allow state in app-context to control the resetting of the error boundary.
  useEffect(() => {
    if (!errorBoundary) {
      resetBoundary();
    }
  }, [errorBoundary]);

  return (
    <div className="w-full max-w-md m-auto text-center p-6">
      <UTurnIcon className={"mx-auto mb-3"} />
      <h1 className="mb-3 text-2xl font-bold">Oops, Something Went Wrong</h1>
      <p className="mb-3">
        The page you&apos;re looking for is temporarily unavailable. Please try your search again.
      </p>
      <div className="flex justify-center">
        <Button
          icon={
            <>
              <ReplayIcon className="fill-primary-600" />
            </>
          }
          iconPosition="before"
          onClick={() => {
            resetBoundary();
          }}
          text="Refresh"
          variant="secondary"
        />
      </div>
    </div>
  );
};

const ErrorWrapper = (props) => {
  const { criticalError } = useAppContext();
  return criticalError ? (
    <ErrorComponent />
  ) : (
    <>
      <ErrorBoundary FallbackComponent={ErrorComponent}>{props.children}</ErrorBoundary>
    </>
  );
};

export default ErrorWrapper;
