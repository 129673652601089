import propTypes from "prop-types";

/**
 * LocationPinIcon icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const LocationPinIcon = ({ className = "" }) => {
  return (
    <>
      <svg viewBox="0 0 32 32" className={className}>
        <path d="M16 2.6665C10.84 2.6665 6.66669 6.83984 6.66669 11.9998C6.66669 18.9998 16 29.3332 16 29.3332C16 29.3332 25.3334 18.9998 25.3334 11.9998C25.3334 6.83984 21.16 2.6665 16 2.6665ZM16 15.3332C14.16 15.3332 12.6667 13.8398 12.6667 11.9998C12.6667 10.1598 14.16 8.6665 16 8.6665C17.84 8.6665 19.3334 10.1598 19.3334 11.9998C19.3334 13.8398 17.84 15.3332 16 15.3332Z" />
      </svg>
    </>
  );
};

LocationPinIcon.propTypes = {
  className: propTypes.string,
};

export default LocationPinIcon;
