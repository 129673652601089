import propTypes from "prop-types";

/**
 * Menu icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const MenuIcon = ({ className = "" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <g id="menu">
        <path id="Vector" d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#737373" />
      </g>
    </svg>
  );
};

MenuIcon.propTypes = {
  className: propTypes.string,
};

export default MenuIcon;
