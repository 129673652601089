import { PropsWithChildren, useEffect } from "react";

import { isValidHexColor } from "lib/color-utility";

import { useAppContext } from "./app-context";

const createTheme = (primaryColor?: string): { [key: string]: string } | null => {
  if (primaryColor) {
    if (primaryColor.startsWith("#")) {
      if (isValidHexColor(primaryColor)) {
        return {
          "--primary-600": primaryColor,
        };
      } else {
        console.warn(`Invalid Hex Color: "${primaryColor}". Will use default primary color.`);
      }
    } else if (primaryColor.length) {
      console.warn(
        `Unexpected format for Hex Color: "${primaryColor}". Will use default primary color.`
      );
    }
  }
  return null;
};

const writeTheme = (theme: { [key: string]: string }) => {
  const entries = Object.entries(theme);
  for (const [property, value] of entries) {
    document.documentElement.style.setProperty(property, value);
  }
};

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const { configuration, setThemeLoaded } = useAppContext();

  useEffect(() => {
    if (configuration) {
      const theme = createTheme(configuration.primaryColor);
      if (theme) {
        writeTheme(theme);
      }
      setThemeLoaded(true);
    }
  }, [configuration]);

  return <>{children}</>;
};
