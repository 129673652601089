import { PropsWithChildren } from "react";

import { ApolloProvider } from "@apollo/client";

import TrackingProvider from "components/tracking-provider/tracking-provider";
import URLCleaner from "components/url-cleaner";
import { client } from "lib/graphql-client";
import { AppContextProvider } from "store/app-context";
import { CarouselContextProvider } from "store/carousel-context";
import { FacetsContextProvider } from "store/facets-context";
import { FiltersContextProvider } from "store/filters-context";
import { FiltersPrep } from "store/filters-prep";
import { JourneyPrep } from "store/journey-prep";
import { ThemeProvider } from "store/theme-provider";
import { ToastContextProvider } from "store/toast-context";
import { UserContextProvider } from "store/user-context";

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <JourneyPrep>
          <URLCleaner>
            <ThemeProvider>
              <UserContextProvider>
                <TrackingProvider>
                  <FacetsContextProvider>
                    <FiltersPrep>
                      <FiltersContextProvider>
                        <CarouselContextProvider>
                          <ToastContextProvider>{children}</ToastContextProvider>
                        </CarouselContextProvider>
                      </FiltersContextProvider>
                    </FiltersPrep>
                  </FacetsContextProvider>
                </TrackingProvider>
              </UserContextProvider>
            </ThemeProvider>
          </URLCleaner>
        </JourneyPrep>
      </AppContextProvider>
    </ApolloProvider>
  );
};
