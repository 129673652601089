import {
  BodyType,
  CreditScore,
  FilterKey,
  IMakeModels,
  VehicleSortOrder,
} from "../../types/filters";

import {
  bodyTypeOptions,
  carfaxHistoryOptions,
  carfaxValueOptions,
  creditScoreOptions,
  DriveTrainOption,
  driveTrainOptions,
  exteriorColorOptions,
  fuelTypeOptions,
  interiorColorOptions,
  mileageConversionOptions,
  priceConversionOptions,
  sortOptions,
  termOptions,
  transmissionOptions,
  vehicleConditionOptions,
} from "./filterOptions";

export const decodeStringBoolean = (val: string): boolean => {
  if (val === "true") return true;
  if (val === "false") return false;
  return false;
};

export const decodeStringNumber = (val: string): number => {
  const num = Number(val);
  return num;
};

export const decodeStringArray = (val: string): string[] => {
  return val.split(",");
};

export const decodeZip = (zip: string): string => {
  return zip;
};

export const decodeIsMotorTrendCertified = (isMTCertified: string): boolean => {
  return decodeStringBoolean(isMTCertified);
};

export const decodeMakesModels = (makesAndModelsStr: string): IMakeModels[] => {
  const arr: IMakeModels[] = [];
  const makesAndModels = makesAndModelsStr.split(",");

  for (const mnm of makesAndModels) {
    const [make, ...models] = mnm.split(".");
    arr.push({ make, models });
  }

  return arr;
};

export const decodeBodyTypes = (bodytypes: string): BodyType[] => {
  const decodedArray = decodeStringArray(bodytypes) as BodyType[];
  return decodedArray.filter((bodytype) => bodyTypeOptions.includes(bodytype));
};

export const decodeMinPrice = (price: string): number => {
  const decodedValue = decodeStringNumber(price);
  if (priceConversionOptions.includes(decodedValue / 1000)) {
    return decodedValue;
  }
  return priceConversionOptions[0] * 1000;
};

export const decodeMaxPrice = (price: string): number => {
  const decodedValue = decodeStringNumber(price);
  if (priceConversionOptions.includes(decodedValue / 1000)) {
    return decodedValue;
  }
  return priceConversionOptions[priceConversionOptions.length - 1] * 1000;
};

export const decodeHasPrice = (hasPrice: string): boolean => {
  return decodeStringBoolean(hasPrice);
};

export const decodeMaxMileage = (mileage: string): number => {
  const decodedValue = decodeStringNumber(mileage);
  if (mileageConversionOptions.includes(decodedValue / 1000)) {
    return decodedValue;
  }
  return mileageConversionOptions[0] * 1000;
};

export const decodeDistance = (distance: string): number => {
  return decodeStringNumber(distance);
};

export const decodeMinYear = (minYear: string): undefined | number => {
  if (minYear === "undefined") return undefined;
  return decodeStringNumber(minYear);
};

export const decodeMaxYear = (maxYear: string): undefined | number => {
  if (maxYear === "undefined") return undefined;
  return decodeStringNumber(maxYear);
};

export const decodeCarfaxHistory = (carfax: string): string[] => {
  const decodedArray = decodeStringArray(carfax);
  return decodedArray.filter((carfax) => carfaxHistoryOptions.includes(carfax));
};

export const decodeCarfaxValue = (carfax: string): string[] => {
  const decodedArray = decodeStringArray(carfax);
  return decodedArray.filter((carfax) => carfaxValueOptions.includes(carfax));
};

export const decodeInteriorColors = (color: string): string[] => {
  const decodedArray = decodeStringArray(color);
  return decodedArray.filter((color) => [...interiorColorOptions, "Other"].includes(color));
};

export const decodeExteriorColors = (color: string): string[] => {
  const decodedArray = decodeStringArray(color);
  return decodedArray.filter((color) => [...exteriorColorOptions, "Other"].includes(color));
};

export const decodeDrivetrain = (drivetrain: string): string[] => {
  const decodedArray = decodeStringArray(drivetrain) as DriveTrainOption[];
  return decodedArray.filter((drivetrain) => driveTrainOptions.includes(drivetrain));
};

export const decodeTransmission = (transmission: string): string[] => {
  const decodedArray = decodeStringArray(transmission);
  return decodedArray.filter((transmission) => transmissionOptions.includes(transmission));
};

export const decodeVehicleCondition = (condition: string): string[] => {
  const decodedArray = decodeStringArray(condition);
  return decodedArray.filter((condition) => vehicleConditionOptions.includes(condition));
};

export const decodePage = (page: string): number => {
  return decodeStringNumber(page);
};

export const decodeItemsPerPage = (ipp: string): number => {
  return decodeStringNumber(ipp);
};

export const decodeSortOrder = (order: string): VehicleSortOrder => {
  if (sortOptions.includes(order as VehicleSortOrder)) {
    return order as VehicleSortOrder;
  }
  return sortOptions[0];
};

export const decodeFuelType = (fuel: string): string[] => {
  const decodedArray = decodeStringArray(fuel);
  return decodedArray.filter((fuel) => fuelTypeOptions.includes(fuel));
};

export const decodeCreditScore = (score: string): CreditScore => {
  return creditScoreOptions.find((option) => option === score) || "PRIME"; // fallback to PRIME score
};

export const decodeTerm = (term: string): number => {
  return termOptions.find((option) => option === Number(term)) || 72; // fallback to 72
};

export const decodeMapper = (key: FilterKey) => {
  switch (key) {
    case "zip":
      return decodeZip;
    case "ismotortrendcertified":
      return decodeIsMotorTrendCertified;
    case "makesmodels":
      return decodeMakesModels;
    case "bodytypes":
      return decodeBodyTypes;
    case "minprice":
      return decodeMinPrice;
    case "maxprice":
      return decodeMaxPrice;
    case "hasprice":
      return decodeHasPrice;
    case "maxmileage":
      return decodeMaxMileage;
    case "distance":
      return decodeDistance;
    case "minyear":
      return decodeMinYear;
    case "maxyear":
      return decodeMaxYear;
    case "carfax":
      return decodeCarfaxHistory;
    case "carfaxvalue":
      return decodeCarfaxValue;
    case "interiorcolors":
      return decodeInteriorColors;
    case "exteriorcolors":
      return decodeExteriorColors;
    case "drivetrain":
      return decodeDrivetrain;
    case "transmission":
      return decodeTransmission;
    case "page":
      return decodePage;
    case "itemsperpage":
      return decodeItemsPerPage;
    case "sortorder":
      return decodeSortOrder;
    case "fueltype":
      return decodeFuelType;
    case "condition":
      return decodeVehicleCondition;
    case "maxpayment":
      return decodeStringNumber;
    case "minpayment":
      return decodeStringNumber;
    case "tradein":
      return decodeStringNumber;
    case "creditscore":
      return decodeCreditScore;
    case "term":
      return decodeTerm;
    case "downamount":
      return decodeStringNumber;
    case "downpercent":
      return decodeStringNumber;
    default:
      return () => {
        return "";
      };
  }
};
