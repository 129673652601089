import propTypes from "prop-types";

/**
 * Replay icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const ReplayIcon = ({ className = "" }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" className={`${className}`}>
      <path d="M9.99998 4.16659V0.833252L5.83331 4.99992L9.99998 9.16659V5.83325C12.7583 5.83325 15 8.07492 15 10.8333C15 13.5916 12.7583 15.8333 9.99998 15.8333C7.24165 15.8333 4.99998 13.5916 4.99998 10.8333H3.33331C3.33331 14.5166 6.31665 17.4999 9.99998 17.4999C13.6833 17.4999 16.6666 14.5166 16.6666 10.8333C16.6666 7.14992 13.6833 4.16659 9.99998 4.16659Z" />
    </svg>
  );
};

ReplayIcon.propTypes = {
  className: propTypes.string,
};

export default ReplayIcon;
