import React from "react";

import AppContent from "app-content";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { cleanQueryParamNames, queryKeyMap } from "components/url-cleaner";
import konsole from "lib/konsole";
import { initTelemetry } from "lib/telemetry-providers";

import { AppContextProvider } from "store/app-context";

import { AppProviders } from "./providers";

import reportWebVitals from "./reportWebVitals";

import "lib/query-shadow";
import "./index.scss";

initTelemetry();

const rootContainer = document.getElementById("root");

if (rootContainer === null) throw new Error("Root container is not available!");

const root = ReactDOM.createRoot(rootContainer);

const App = () => {
  // Provide way to enable konsole logging.
  window.dtc = {};
  window.dtc.enableConsole = konsole.enable;
  window.dtc.disableConsole = konsole.disable;

  // Get the current href and clean the query parameter names (i.e. correct any issues with case variations in their names)
  const cleanedUrlString = cleanQueryParamNames(window.location.href, queryKeyMap);
  if (cleanedUrlString !== window.location.href) {
    window.history.replaceState(null, "", cleanedUrlString);
  }

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppContextProvider>
          <AppProviders>
            <AppContent />
          </AppProviders>
        </AppContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
