interface ILoading {
  forButton?: boolean;
}

const Loading = (props: ILoading) => {
  const { forButton = false } = props;

  return (
    <>
      {forButton ? (
        <>
          <div className="flex justify-center h-6">
            <img src="/images/preloader-neutral-frames.gif" alt="Loading results" />
          </div>
        </>
      ) : (
        <div className="flex justify-center pt-28 h-fit">
          <img src="/images/preloader.gif" alt="Loading results" />
        </div>
      )}
    </>
  );
};

export default Loading;
