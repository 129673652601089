import propTypes from "prop-types";

/**
 * Search icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
export const SearchIcon = ({ className = "" }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" className={`${className}`}>
      <path d="M12.755 11.5405H11.965L11.685 11.2705C12.665 10.1305 13.255 8.65053 13.255 7.04053C13.255 3.45053 10.345 0.540527 6.755 0.540527C3.165 0.540527 0.255005 3.45053 0.255005 7.04053C0.255005 10.6305 3.165 13.5405 6.755 13.5405C8.365 13.5405 9.845 12.9505 10.985 11.9705L11.255 12.2505V13.0405L16.255 18.0305L17.745 16.5405L12.755 11.5405ZM6.755 11.5405C4.26501 11.5405 2.255 9.53053 2.255 7.04053C2.255 4.55053 4.26501 2.54053 6.755 2.54053C9.245 2.54053 11.255 4.55053 11.255 7.04053C11.255 9.53053 9.245 11.5405 6.755 11.5405Z" />
    </svg>
  );
};

SearchIcon.propTypes = {
  className: propTypes.string,
};

export default SearchIcon;
