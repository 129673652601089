import { PropsWithChildren, useEffect } from "react";

import clsx from "clsx";

import CloseIcon from "./icons/close-icon";

interface IModalProps extends PropsWithChildren {
  showModal: boolean;
  hideCloseButton?: boolean;
  closeOnBackgroundClick?: boolean;
  fitContent?: boolean;
  onModalClose: () => void;
}

const Modal = (props: IModalProps) => {
  const {
    children,
    showModal,
    hideCloseButton = false,
    closeOnBackgroundClick = false,
    fitContent = false,
    onModalClose,
  } = props;

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [showModal]);

  const closeModal = () => {
    onModalClose();
  };

  const handleBackgroundClicked = () => {
    if (closeOnBackgroundClick) {
      closeModal();
    }
  };

  if (!showModal) return null;

  return (
    <div
      className="bg-black/70 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none"
      onClick={() => handleBackgroundClicked()}
    >
      <div
        className="relative my-6 mx-auto max-w-3xl w-full"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={clsx("modal max-w-7xl ml-auto mr-auto", fitContent && "w-fit")}>
          <div className="modal-header bg-white p-6 rounded relative">
            <div className={clsx("absolute right-4 top-4 z-1", hideCloseButton && "hidden")}>
              <div className="flex justify-end w-full">
                <button onClick={closeModal} id="closeModal" aria-label="Close modal">
                  <CloseIcon className="fill-neutral-700" />
                </button>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
