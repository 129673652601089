import { getConfig } from "lib/config";

import {
  ICreateActivityRequest,
  IGetConfigurationRequest,
  IGetSessionRequest,
  ISession,
} from "../../types/session";
import * as REST from "../api-service";

const marketplaceApiUrl = getConfig()["MARKETPLACE_API_URL"];

const API_TIMEOUT = 15000;

export const getOrCreateSession = async (
  request: IGetSessionRequest,
  timeout = API_TIMEOUT
): Promise<ISession> => {
  const url = `${marketplaceApiUrl}/Session/GetOrCreateSession`;
  const response = await REST.postData(url, request, timeout);
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response.error.message || "Failed to get or create session.");
  }
};

export const createActivity = async (
  request: ICreateActivityRequest,
  timeout = API_TIMEOUT
): Promise<void> => {
  const url = `${marketplaceApiUrl}/Session/CreateActivity`;
  await REST.postData(url, request, timeout);
  // TODO: Check response for errors and log them
};

export const getConfiguration = async (
  request: IGetConfigurationRequest,
  timeout = API_TIMEOUT
): Promise<void> => {
  const url = `${marketplaceApiUrl}/TenantGeneratorConfiguration/GetConfiguration?tenantID=${request.tenantID}&generatorID=${request.generatorID}`;
  const response = await REST.getData(url, timeout);
  if (response.data) {
    return response.data;
  } else {
    return Promise.reject(response.error.message || "Failed to get configuration.");
  }
};
