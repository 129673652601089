import { useContext } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import { getZipCode } from "lib/api-calls";

import AppContext from "store/app-context";
import ToastContext from "store/toast-context";

import TargetIcon from "../icons/target-icon";
import { EState } from "../toast";

const ZipCodeInput = ({
  errorMessage,
  register,
  required,
  setZipCode,
  onKeyDown: keyDown = () => {},
  className = "",
}) => {
  const { setLatLong, gettingLatLong, setGettingLatLong } = useContext(AppContext);
  const { setToastMsg, setShowToast, setToastEnum } = useContext(ToastContext);
  const getUserLatLong = () => {
    if (navigator.geolocation) {
      setGettingLatLong(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLatLong({ latitude: position.coords.latitude, longitude: position.coords.longitude });
          const zipResults = await getZipCode(position.coords, 5000);
          setGettingLatLong(false);
          if (zipResults.data) {
            setZipCode(zipResults.data, { shouldValidate: true });
          } else if (zipResults.error) {
            setGettingLatLong(false);
            setToastEnum(EState.WARN);
            setToastMsg(zipResults.error.message);
            setShowToast(true);
          }
        },
        (positionError) => {
          setGettingLatLong(false);
          setToastEnum(EState.WARN);
          setToastMsg(positionError.message);
          setShowToast(true);
        }
      );
    } else {
      setGettingLatLong(false);
      setToastEnum(EState.WARN);
      setToastMsg("Geolocation is not supported by this browser.");
      setShowToast(true);
    }
  };

  const onKeyDown = () => {
    setLatLong(undefined);
    if (keyDown) {
      keyDown();
    }
  };

  return (
    <div>
      <div className={"relative " + className}>
        <label htmlFor="zipcode" className="text-neutral-700 text-xs font-bold">
          Zip Code
        </label>
        <input
          id="zipcode"
          type="text"
          inputMode="numeric"
          pattern="\d*"
          className={clsx(
            "border border-neutral-300 rounded-md p-3 w-full",
            errorMessage && "error border-red-500 bg-red-50 placeholder-red-600 text-red-600"
          )}
          aria-label="ZIP"
          placeholder="12345"
          {...register}
          required={required}
          onKeyDown={onKeyDown}
          data-testid="zip-code-input"
        />
        <div
          className={clsx(
            "z-1 cursor-pointer absolute top-1/2 right-3",
            gettingLatLong && "pointer-events-none"
          )}
          onClick={getUserLatLong}
          data-testid="get-location"
        >
          <TargetIcon
            className={gettingLatLong ? "animate-spin fill-primary-600" : "fill-neutral-500"}
          />
        </div>
      </div>
      {errorMessage && <span className="text-red-600 text-xs ">{errorMessage}</span>}
    </div>
  );
};

ZipCodeInput.propTypes = {
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default ZipCodeInput;
