import { createContext, PropsWithChildren, useContext, useState } from "react";

import { voidFunction } from "helpers/generic";
import { FacetResultOfString, InventoryVehicleFacets } from "types/__generated__/graphql";

interface IFacetsContext {
  facets: InventoryVehicleFacets & { totalCount: number };
  setFacets: (data: InventoryVehicleFacets & { totalCount: number }) => void;
}

export const defaultContext: IFacetsContext = {
  facets: {
    decoded: {
      make: [],
      bodyType: [],
      exteriorColor: [],
      interiorColor: [],
      condition: [],
    },
    leadSupport: {
      isMotorTrendCertified: [],
    },
    transmissionCategory: [],
    drivetrainCategory: [],
    fuelTypeCategory: [],
    carFax: {
      accidentOrDamage: [],
      numberOfOwners: [],
      vehicleUse: [],
      carFaxValue: [],
    },
    totalCount: 0,
  },
  setFacets: voidFunction,
};

const FacetsContext = createContext(defaultContext);

export const FacetsContextProvider = ({ children }: PropsWithChildren) => {
  const [facets, setFacets] = useState<IFacetsContext["facets"]>(defaultContext.facets);
  const context: IFacetsContext = {
    facets,
    setFacets: (data) => {
      if (data.decoded) {
        data.decoded.make = data.decoded?.make?.filter((make) => make.value !== "");
      }
      setFacets(data);
    },
  };

  return <FacetsContext.Provider value={context}>{children}</FacetsContext.Provider>;
};

// Converts the body types returned by facets into the display names used by the filters
export const convertBodyTypeFacetsForFilters = (bodyTypes: FacetResultOfString[]) => {
  const combinedFacetsMap = new Map<string, string>([
    ["Van", "Van/Minivan"],
    ["SUV", "SUV/Crossover"],
  ]);

  return bodyTypes.map((bodyType) => {
    return {
      ...bodyType,
      value: combinedFacetsMap.get(bodyType.value || "") || bodyType.value,
    };
  });
};

export const useFacetsContext = () => {
  const context = useContext(FacetsContext);
  if (context === undefined) {
    throw new Error("useFacetsContext must be used within a FacetsContextProvider");
  }
  return context;
};
