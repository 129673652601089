import { createContext, FC, ReactNode, useContext, useState } from "react";

interface IUserContext {
  firstName?: string;
  setFirstName?: (data: string) => void;
  lastName?: string;
  setLastName?: (data: string) => void;
  emailAddress?: string;
  setEmailAddress?: (data: string) => void;
  phoneNumber?: string;
  setPhoneNumber?: (data: string) => void;
  children?: ReactNode;
}

const UserContext = createContext<IUserContext>({});

export const UserContextProvider: FC<IUserContext> = (props) => {
  const { children } = props;
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const context: IUserContext = {
    firstName,
    setFirstName: (data) => {
      setFirstName(data);
    },
    lastName,
    setLastName: (data) => {
      setLastName(data);
    },
    emailAddress,
    setEmailAddress: (data) => {
      setEmailAddress(data);
    },
    phoneNumber,
    setPhoneNumber: (data) => {
      // Remove non-digit characters
      data.replace(/\D/g, "");
      setPhoneNumber(data);
    },
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export default UserContext;

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};
