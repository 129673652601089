/* TODO:
 * 1. Verify that we need tracePropagation and that it works
 * 2. Send environment to Sentry (foundry widgets used Local/QA/Prod)
 * 3. Figure out if we need/want sentry replay
 * 4. Do we need to use Sentry.captureException and link it with error boundary like widgets?
 */

import config from "../config.json";
const { domainToConfigKeyMap, base: baseConfig } = config;

const hostname = window.location.hostname;
const configKey = domainToConfigKeyMap[hostname];

// Lookup configs by hostname to override defaults or add new env specific keys
const hostnameConfig = config[configKey];

export const getConfig = () => ({
  ...baseConfig,
  ...hostnameConfig,
  // Add any computed configurations here. These keys will exist for all environments,
  // but the value may vary based on the environment if they are computed based on hostname.
  MARKETPLACE_API_URL: `${hostnameConfig["BFF_BASE_URL"]}/api/v1`,
  GRAPHQL_URL: `${hostnameConfig["GRAPH_BASE_URL"]}/graphql`,
  // Intended env values: Local, QA, Production
  SENTRY_ENVIRONMENT: hostname === "localhost" ? "Local" : configKey,
});
