import { useContext, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";

import Button from "components/button/button";
import ZipCodeInput from "components/form/zip-code-input";
import LocationPinIcon from "components/icons/location-pin-icon";
import SearchIcon from "components/icons/search-icon";
import { getCoordinatesFromZip } from "lib/api-calls";
import konsole from "lib/konsole";
import AppContext from "store/app-context";

const ZipCodeForm = (props) => {
  const { onModalClose, setCriticalError = () => {} } = props;
  const { setLatLong, setGettingLatLong, setZip } = useContext(AppContext);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [invalidUSZipCode, setInvalidUSZipCode] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .required("Please enter valid US ZIP Code")
      .matches(/(^\d{5}$)/, "Please enter valid US ZIP Code"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
  } = useForm({
    defaultValues: {
      zipCode: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    konsole.log("data :", data);

    try {
      setGettingLatLong(true);
      setDisableSubmitButton(true);
      const latLong = await getCoordinatesFromZip(data.zipCode, 5000);
      setGettingLatLong(false);
      setDisableSubmitButton(false);
      setLatLong(latLong);

      setZip(data.zipCode);
      onModalClose();
      const params = {};

      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      setSearchParams({ ...params, zip: data.zipCode }, { replace: true });
    } catch (error) {
      setGettingLatLong(false);
      setDisableSubmitButton(false);

      if (typeof error === "string") {
        if (error.indexOf("422") >= 0) {
          setInvalidUSZipCode(true);
        } else {
          setCriticalError(true);
        }
      } else {
        setCriticalError(true);
      }
    }
  };

  // Provide a mechanism for the zip-code-input field to update the forms' zip code state when the location spinner is being used.
  const setZipCode = (data, options) => {
    resetField("zipCode", { keepError: false, keepDirty: true, keepTouched: true });
    setValue("zipCode", data, options);
  };

  const onZipCodeKeyDown = () => {
    setInvalidUSZipCode(false);
  };

  return (
    <div className="w-56">
      <div className="flex flex-col relative">
        <form onSubmit={handleSubmit(onSubmit)} noValidate className="flex flex-col items-center">
          <LocationPinIcon className={"w-20 fill-neutral-500 mb-2"} />
          <div className="text-neutral-700 font-bold text-2xl mb-3">Set Your Location</div>
          <ZipCodeInput
            onKeyDown={onZipCodeKeyDown}
            error={invalidUSZipCode || (errors.zipCode && errors.zipCode.message) ? true : false}
            errorMessage={
              invalidUSZipCode ? "Please enter valid US Zip Code" : errors.zipCode?.message
            }
            register={register("zipCode")}
            setZipCode={setZipCode}
            required
          />
          <Button
            icon={<SearchIcon className="fill-white" direction="right" />}
            text={"Search"}
            variant="primary"
            iconPosition="after"
            type="submit"
            disabled={disableSubmitButton}
            fullWidth
            className={"my-4"}
            ariaLabel={"Search"}
          />
        </form>
      </div>
    </div>
  );
};

export default ZipCodeForm;
