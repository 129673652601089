import { useEffect, useRef, useState } from "react";

import { ILink } from "types/session";

import CloseMenuIcon from "./icons/close-menu-icon";
import MenuIcon from "./icons/menu-icon";

const URLMenu = ({ links }: { links: ILink[] }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfEventIsFromOutsideTheMenu = (e: UIEvent | Event) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfEventIsFromOutsideTheMenu);
    document.addEventListener("scroll", checkIfEventIsFromOutsideTheMenu);
    document.addEventListener("wheel", checkIfEventIsFromOutsideTheMenu);

    return () => {
      document.removeEventListener("mousedown", checkIfEventIsFromOutsideTheMenu);
      document.removeEventListener("scroll", checkIfEventIsFromOutsideTheMenu);
      document.removeEventListener("wheel", checkIfEventIsFromOutsideTheMenu);
    };
  }, [isMenuOpen]);

  const handleToggleTooltip = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (!links.length) return null;

  return (
    <div className="relative" ref={ref}>
      <div onClick={handleToggleTooltip}>
        <div className="mr-2 cursor-pointer">
          {isMenuOpen ? <CloseMenuIcon className="m-2" /> : <MenuIcon className="m-2" />}
        </div>
      </div>
      {isMenuOpen && (
        <div className="absolute top-12">
          <div
            className="absolute z-2 rounded bg-white text-xs border-2 border-neutral-200 overflow-hidden"
            onClick={handleToggleTooltip}
          >
            <div className="w-60 text-primary-600 font-bold">
              {links.map((link, index) => (
                <div
                  key={index}
                  className="min-h-11 h-fit leading-10 border-b-2 last:border-b-0 px-4 hover:bg-neutral-50 active:bg-neutral-100"
                >
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="min-h-11 leading-10 border-b-2 last:border-b-0 text-sm h-fit"
                  >
                    <p>{link.displayText}</p>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-0 h-0 border-l-[12px] border-l-transparent border-r-[12px] border-r-transparent border-b-[12px] border-b-neutral-200 absolute -top-[10px] left-2 z-10" />
          <div className="w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-white absolute -top-[7px] left-2.5 z-10" />
        </div>
      )}
    </div>
  );
};

export default URLMenu;
