import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { ILatLong } from "types/geography";
import { IConfiguration, IMetadata, ISession } from "types/session";

import { Tab } from "components/filters/price-and-payment/types";
import { useMediaQuery } from "hooks/use-media-query";
import { getCoordinatesFromZip } from "lib/api-calls";

export enum ZipStatus {
  Validating = "validating",
  Valid = "valid",
  Invalid = "invalid",
}

interface IAppContext {
  errorBoundary?: boolean;
  criticalError?: boolean;
  tryAgainError?: boolean;
  error?: string;
  zip?: string;
  latLong?: ILatLong;
  gettingLatLong?: boolean;
  setGettingLatLong?: (value: boolean) => void;
  setCriticalError?: (data: boolean) => void;
  setTryAgainError: (data: boolean) => void;
  setErrorBoundary: (data: boolean) => void;
  setError?: (data: string) => void;
  setZip: (data: string) => void;
  setLatLong: (data: ILatLong) => void;
  footerLinkSelected?: string;
  setFooterLinkSelected?: (value: string) => void;
  session: ISession;
  setSession?: (value: {
    sessionCode?: string;
    journeyCode?: string;
    tenantID?: number;
    generatorID?: string;
    secondaryGeneratorID?: string;
    currentActivityID?: number;
  }) => void;
  configuration: IConfiguration | undefined;
  setConfiguration?: (data: IConfiguration) => void;
  metadata?: IMetadata | undefined;
  setMetadata?: (data: IMetadata) => void;
  children?: ReactNode;
  srpShown?: boolean;
  setSRPShown: (data: boolean) => void;
  zipStatus: ZipStatus;
  setZipStatus: Dispatch<SetStateAction<ZipStatus>>;
  showZipModal: boolean;
  setShowZipModal: Dispatch<SetStateAction<boolean>>;
  themeLoaded: boolean;
  setThemeLoaded: (data: boolean) => void;

  isMobile: boolean;
  isDesktop: boolean;
  showFilters: boolean;
  areMobileFiltersOpen: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  activeTab: Tab;
  setActiveTab: (tab: Tab) => void;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppContextProvider = (props: PropsWithChildren) => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryZip = urlParams.get("zip") || "";

  const [errorBoundary, setErrorBoundary] = useState<boolean>(false);
  const [criticalError, setCriticalError] = useState<boolean>(false);
  const [tryAgainError, setTryAgainError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [zip, setZip] = useState<string>(queryZip);
  const [zipStatus, setZipStatus] = useState<ZipStatus>(ZipStatus.Validating);
  const [latLong, setLatLong] = useState<ILatLong>({ latitude: 0, longitude: 0 });
  const [gettingLatLong, setGettingLatLong] = useState<boolean>(false);
  const [footerLinkSelected, setFooterLinkSelected] = useState<string>("");
  const [srpShown, setSRPShown] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.Price);
  const [session, setSession] = useState<ISession>({
    sessionCode: "",
    journeyCode: "",
    tenantID: 0,
    generatorID: "",
    secondaryGeneratorID: "",
    currentActivityID: 0,
  });
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width: 599px)");
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [showFilters, setShowFilters] = useState<boolean>(isDesktop);
  const areMobileFiltersOpen = showFilters && isMobile;

  const [configuration, setConfiguration] = useState<IConfiguration | undefined>(undefined);

  const [metadata, setMetadata] = useState<IMetadata | undefined>(undefined);

  const [showZipModal, setShowZipModal] = useState(false);

  useEffect(() => {
    const getCoordinates = async () => {
      setZipStatus(ZipStatus.Validating);
      try {
        const _latLong = await getCoordinatesFromZip(zip, 5000);
        setLatLong(_latLong);
        setZipStatus(ZipStatus.Valid);
      } catch (er) {
        setZipStatus(ZipStatus.Invalid);
      }
    };
    if (
      ["/search-results", "/vehicle-detail"].includes(window.location.pathname.replace(/\/$/, ""))
    ) {
      if (zip) {
        getCoordinates();
      } else {
        setZipStatus(ZipStatus.Invalid);
      }
    }
  }, [zip]);
  const context: IAppContext = {
    errorBoundary,
    criticalError,
    tryAgainError,
    error,
    zip,
    setZip,
    latLong,
    gettingLatLong,
    setGettingLatLong,
    setErrorBoundary,
    setCriticalError: (data) => {
      setCriticalError(data);
    },
    setTryAgainError,
    setError: (data) => {
      setError(data);
    },
    setLatLong: (data: ILatLong) => {
      setLatLong(data);
    },
    footerLinkSelected,
    setFooterLinkSelected,
    session,
    setSession,
    configuration,
    setConfiguration,
    metadata,
    setMetadata,
    srpShown,
    setSRPShown,
    zipStatus,
    setZipStatus,
    showZipModal,
    setShowZipModal,
    isMobile,
    isDesktop,
    showFilters,
    areMobileFiltersOpen,
    setShowFilters,
    themeLoaded,
    setThemeLoaded,
    activeTab,
    setActiveTab,
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
};

export default AppContext;

// Create a custom hook for using the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};
