import { createContext, PropsWithChildren, useContext, useState } from "react";

import {
  carFilterKeys,
  defaultCarFilters,
  defaultFilters,
  defaultNavigationFilters,
  deserializeUrl,
  encodeFilterKey,
} from "helpers/filters";

import { voidFunction } from "helpers/generic";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { AccordionFilterKey, FilterKey, Filters, IMakeModels } from "types/filters";

import {
  dollarAmountFilterKeys,
  monthlyPaymentFilterKeys,
} from "components/filters/price-and-payment/keys";

type TFiltersContext = {
  getFilter: (
    filterKey: FilterKey
  ) => string | number | boolean | string[] | IMakeModels[] | null | undefined;
  setFilter: (filterKey: FilterKey, val: unknown) => void;
  undoFilters: () => void;
  getNumOfFiltersDifferentFromDefaults: () => number;
  isFilterValueDirty: (filterKey: AccordionFilterKey) => boolean;
  areFiltersDirty: () => boolean;
  resetFilters: (...filterKeys: FilterKey[]) => void;
  getCurrentFilters: () => Filters;
  takeFiltersSnapshot: () => void;
  snapshot: string;
  cloneMakesModels: (makesModels: IMakeModels[]) => IMakeModels[];
  getDirtyFilters: () => Partial<Filters>;
};

const initialState: TFiltersContext = {
  getFilter: () => null,
  setFilter: voidFunction,
  undoFilters: voidFunction,
  getNumOfFiltersDifferentFromDefaults: () => 0,
  isFilterValueDirty: () => false,
  areFiltersDirty: () => false,
  resetFilters: voidFunction,
  getCurrentFilters: () => defaultFilters,
  takeFiltersSnapshot: voidFunction,
  snapshot: "",
  cloneMakesModels: () => [],
  getDirtyFilters: () => ({}),
};

export const FiltersContext = createContext(initialState);

export const FiltersContextProvider = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [snapshot, setSnapshot] = useState("");

  const takeFiltersSnapshot = () => {
    setSnapshot(searchParams.toString());
  };

  const getCurrentFilters = () => {
    return deserializeUrl(searchParams.toString());
  };

  const getFilter = (filterKey: FilterKey) => {
    const filters = getCurrentFilters();
    return filters[filterKey];
  };

  const undoFilters = () => {
    setSearchParams(snapshot);
  };

  const setFilter = (filterKey: FilterKey, newVal: unknown) => {
    const filters = getCurrentFilters();
    if (newVal === filters[filterKey]) return;
    const { key, val } = encodeFilterKey(filterKey, newVal);
    setSearchParams((params) => {
      if (val) {
        params.set(key, val);
      } else {
        params.delete(key);
      }

      if (key !== "page") {
        params.set("page", "1");
      }

      return params;
    });
  };

  const getNumOfFiltersDifferentFromDefaults = () => {
    const filters = getCurrentFilters();
    let cnt = 0;
    const arrayTypeFilters: FilterKey[] = [
      "carfax",
      "carfaxvalue",
      "interiorcolors",
      "exteriorcolors",
      "drivetrain",
      "transmission",
      "condition",
      "fueltype",
    ];
    const filterPairs: [string, string, number][] = [
      ["minprice", "maxprice", 0],
      ["minyear", "maxyear", 0],
    ];

    for (const key of carFilterKeys) {
      if (key === "makesmodels") {
        const makesModels = filters[key] as IMakeModels[];
        for (const mm of makesModels) {
          cnt++;
          cnt += mm.models.length;
        }
      } else if (arrayTypeFilters.includes(key)) {
        const filter = filters[key] as string[];
        cnt += filter.length;
      } else if (`${filters[key]}` !== `${defaultCarFilters[key]}`) {
        let updatedFilterPair = false;
        for (const filterPair of filterPairs) {
          if (filterPair[0] === key || filterPair[1] === key) {
            filterPair[2] = 1;
            updatedFilterPair = true;
            break;
          }
        }
        if (!updatedFilterPair) cnt++;
      }
    }
    const numSelectedFilterPairs = filterPairs.reduce((acc, curr) => acc + curr[2], 0);
    return cnt + numSelectedFilterPairs;
  };

  const isFilterValueDirty = (filterKey: AccordionFilterKey): boolean => {
    const filters = getCurrentFilters();
    if (filterKey === "price-payment") {
      for (const key of [...monthlyPaymentFilterKeys, ...dollarAmountFilterKeys]) {
        if (defaultFilters[key] !== filters[key]) {
          return true;
        }
      }
      return false;
    }
    if (filterKey === "carfax") {
      return (
        defaultFilters.carfax !== filters.carfax ||
        defaultFilters.carfaxvalue !== filters.carfaxvalue
      );
    }
    if (filterKey === "year") {
      return (
        defaultFilters.minyear !== filters.minyear || defaultFilters.maxprice !== filters.maxprice
      );
    }
    if (filterKey === "makesmodels") {
      return (
        encodeFilterKey("makesmodels", defaultFilters.makesmodels).val !==
        encodeFilterKey("makesmodels", filters.makesmodels).val
      );
    }
    return `${defaultFilters[filterKey]}` !== `${filters[filterKey]}`;
  };

  const areFiltersDirty = (): boolean => {
    const filterKeys = Object.keys(defaultCarFilters) as FilterKey[];
    for (const key of filterKeys) {
      if (isFilterValueDirty(key)) {
        return true;
      }
    }
    return false;
  };

  const resetFilters = (...args: FilterKey[]) => {
    if (!args.length) {
      const currentZip = getFilter("zip");
      const params = {
        page: `${defaultNavigationFilters.page}`,
        itemsperpage: `${defaultNavigationFilters.itemsperpage}`,
        sortorder: defaultNavigationFilters.sortorder,
        zip: currentZip as string,
      };
      setSearchParams(createSearchParams(params)); // createSearchParams wants every value to be string | string[]
    } else {
      setSearchParams((params) => {
        for (const key of args) {
          params.delete(key);
        }
        return params;
      });
    }
  };

  const cloneMakesModels = (makesModels: IMakeModels[]): IMakeModels[] => {
    const arr: IMakeModels[] = [];
    for (const mm of makesModels) {
      const make = mm.make;
      const models = [...mm.models];
      arr.push({ make, models });
    }
    return arr;
  };

  const getDirtyFilters = (): Partial<Filters> => {
    const dirtyFilters: Partial<Filters> = {};
    const filters = getCurrentFilters();

    for (const key of carFilterKeys) {
      if (isFilterValueDirty(key)) {
        (dirtyFilters[key] as unknown) = filters[key] as unknown;
      }
    }

    return dirtyFilters;
  };

  const ctx = {
    getFilter,
    setFilter,
    undoFilters,
    getNumOfFiltersDifferentFromDefaults,
    isFilterValueDirty,
    areFiltersDirty,
    resetFilters,
    getCurrentFilters,
    takeFiltersSnapshot,
    cloneMakesModels,
    getDirtyFilters,
    snapshot,
  };

  return <FiltersContext.Provider value={ctx}>{children}</FiltersContext.Provider>;
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  return context;
};
