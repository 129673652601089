import { PropsWithChildren } from "react";

import useSession from "hooks/use-session";

export const JourneyPrep = ({ children }: PropsWithChildren) => {
  const { startOrContinueJourney, configuration } = useSession();

  startOrContinueJourney();

  if (!configuration) return null;

  return <>{children}</>;
};
