import * as RandomId from "random-id";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "store/app-context";

const links = [
  { name: "Terms of Use", path: "/terms-of-use", fragment: "" },
  { name: "Privacy Policy", path: "/privacy-policy", fragment: "" },
  {
    name: "Do Not Sell My Personal Information",
    path: "/privacy-policy",
    fragment: "#data-rights",
  },
];

const FooterLinks = () => {
  const navigate = useNavigate();
  const { setFooterLinkSelected, errorBoundary, setErrorBoundary } = useAppContext();

  const handleClick = (index) => {
    // Ensure the error boundary gets reset.
    if (errorBoundary) {
      setErrorBoundary(false);
    }

    // Use a random id to simulate pub-sub event notification.  This allows the privacy policy page
    // to react to the link being clicked again while already on the privacy policy page.
    const id = `${index} - ${RandomId(5, "aA0")}`;
    setFooterLinkSelected(id);

    // PUSH the new path on to the router history if it differs from the current one.
    if (window.location.pathname !== links[index].path) {
      navigate(links[index].path + links[index].fragment);
    } else {
      // REPLACE the current path in the router history with the selected one if paths only differ by fragment value.
      navigate(links[index].path + links[index].fragment, { replace: true });
    }
  };

  return (
    <div className="text-primary-600 font-bold text-xs pb-2 mx-auto flex justify-center flex-wrap mt-3 max-w-3xl">
      {links.map((link, index) => (
        <div
          key={index}
          className="inline-block ml-4 mr-6 cursor-pointer leading-4 mb-4 select-none"
          onClick={() => handleClick(index)}
        >
          {link.name}
        </div>
      ))}
    </div>
  );
};

export default FooterLinks;
