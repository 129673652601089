import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { getConfig } from "lib/config";

import { sentry } from "./telemetry-providers";

const config = getConfig();
const graphQlUrl = config["GRAPHQL_URL"];

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      sentry.error({
        errorLevel: "error",
        error: new Error(`[GraphQL error]: Message: ${error.message}`),
        message: error.message,
        tags: {
          "error.type": "GraphQL Error",
        },
        extra: {
          locations: error.locations,
          path: error.path,
        },
      });
    });
  }

  if (networkError) {
    sentry.error({
      errorLevel: "error",
      error: networkError,
      message: networkError.message,
      tags: {
        "error.type": "Network Error",
      },
    });
  }
});

const httpLink = new HttpLink({
  uri: graphQlUrl,
});

const link = errorLink.concat(httpLink);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
