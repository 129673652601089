import { useEffect } from "react";

import clsx from "clsx";

import { Outlet, useLocation } from "react-router-dom";

import ErrorWrapper from "components/error/error-wrapper";
import GenericError from "components/error/generic-error";
import Footer from "components/footer/footer";
import ZipCodeForm from "components/form/zip-code-form";
import Logo from "components/logo";
import Modal from "components/modal";
import Toast from "components/toast";

import { useAppContext, ZipStatus } from "store/app-context";

function Layout() {
  const { error, areMobileFiltersOpen, themeLoaded } = useAppContext();
  const { showZipModal, setShowZipModal, setTryAgainError, zipStatus } = useAppContext();
  const { pathname } = useLocation();
  const isSRP = pathname === "/search-results";

  useEffect(() => {
    if (zipStatus === ZipStatus.Invalid) {
      setShowZipModal(true);
    }
  }, [zipStatus, themeLoaded]);

  if (!themeLoaded) return null;

  return (
    <>
      <div className="relative w-full">
        <div className="min-h-screen flex flex-col">
          <div
            id="header"
            className={clsx(
              areMobileFiltersOpen && isSRP && "hidden",
              isSRP ? "lg:sticky" : "sticky",
              "top-0 w-full bg-white z-10 border-b border-neutral-300"
            )}
          >
            <div className="max-w-screen-xl mx-auto">
              <Logo />
            </div>
          </div>
          <div className="flex-grow">
            <ErrorWrapper>
              <Outlet />
            </ErrorWrapper>
          </div>
          <Footer />
        </div>
        <Toast enable={window.location.hostname === "localhost"} />
      </div>
      {error && <GenericError />}
      <Modal
        showModal={showZipModal}
        onModalClose={() => setShowZipModal(false)}
        hideCloseButton={zipStatus === ZipStatus.Invalid}
        closeOnBackgroundClick={zipStatus === ZipStatus.Valid}
        fitContent
      >
        <ZipCodeForm
          setCriticalError={setTryAgainError}
          onModalClose={() => setShowZipModal(false)}
        />
      </Modal>
    </>
  );
}

export default Layout;
