import { useEffect, useState } from "react";

import { gql, useQuery } from "@apollo/client";

import konsole from "lib/konsole";

const STANDARDIZED_URL = gql`
  query StandardizedUrl($url: URL!) {
    vehicleSpecificationsInUrl(url: $url) {
      standardizedUrl
    }
  }
`;

export const queryKeyMap = {
  zip: "zip",
  make: "make",
  model: "model",
  bodytype: "bodyType",
  vin: "vin",
  order: "order",
  mtc: "mtc",
};

// This function accepts a URL as a string and will replace all query parameter names with ones from the
// keyMap if they exist, else with lowercase version of the given query param name.
//
// The resulting URL string is returned.
export function cleanQueryParamNames(url, keyMap) {
  // Split the URL into parts to separate the query string
  const urlParts = url.split("?");

  if (urlParts.length !== 2) {
    // Invalid URL format; return the original URL
    return url;
  }

  const baseUrl = urlParts[0];
  const queryString = urlParts[1];

  // Split the query string into individual parameter key-value pairs
  const queryParams = queryString.split("&");

  // Create an object to store the modified parameters
  const modifiedParams = {};

  queryParams.forEach((param) => {
    const [key, value] = param.split("=");
    const lowerCaseKey = key.toLowerCase();
    const mappedKey = keyMap[lowerCaseKey];
    modifiedParams[mappedKey ? mappedKey : lowerCaseKey] = value;
  });

  // Convert the modified parameters back to a query string
  const modifiedQueryString = Object.entries(modifiedParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  // Reconstruct the URL with the modified query string
  const modifiedUrl = `${baseUrl}?${modifiedQueryString}`;

  konsole.log("modifiedUrl: ", modifiedUrl);
  return modifiedUrl;
}

/**
 * This wrapper is used to standardized the query param values based on logic contained within the back end.  It can do such things as change the case of make and model values to match with what graphQL is expecting.
 * If cleanParamNames is set to true, then it will invoke the cleanQueryParamNames as well.
 * @param {} props
 * @returns
 */
const URLCleaner = (props) => {
  const { cleanParamNames = false } = props;
  const [urlCleansed, setUrlCleansed] = useState(false);
  const url = window.location.href;
  const originalUrl = new URL(url);
  const originalPath = originalUrl.pathname + originalUrl.search;

  const { error: urlError, data: urlData } = useQuery(STANDARDIZED_URL, { variables: { url } });

  useEffect(() => {
    if (!urlCleansed) {
      if (urlData && urlData.vehicleSpecificationsInUrl) {
        const { standardizedUrl } = urlData.vehicleSpecificationsInUrl;
        const cleanedStandardizedUrl = new URL(
          cleanParamNames ? cleanQueryParamNames(standardizedUrl, queryKeyMap) : standardizedUrl
        );
        const newPath = cleanedStandardizedUrl.pathname + cleanedStandardizedUrl.search;

        if (newPath !== originalPath) {
          window.location.replace(newPath);
        }
        setUrlCleansed(true);
      }
    }
  }, [urlData]);

  useEffect(() => {
    if (urlError) {
      konsole.error("index:urlError: ", urlError);
      setUrlCleansed(true);
    }
  }, [urlError]);

  return <>{urlCleansed && props.children}</>;
};

export default URLCleaner;
