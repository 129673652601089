import { PropsWithChildren, useEffect } from "react";

import { getDisabledFilterKeys } from "helpers/filters";
import { useSearchParams } from "react-router-dom";

import { useAppContext } from "./app-context";

export const FiltersPrep = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { configuration } = useAppContext();

  useEffect(() => {
    if (!configuration) return;
    const keysToRemoveFromURL = getDisabledFilterKeys(configuration);
    for (const key of keysToRemoveFromURL) {
      searchParams.delete(key);
    }
    setSearchParams(searchParams, { replace: true });
  }, []);

  return <>{children}</>;
};
