import { FilterKey } from "types/filters";

export const monthlyPaymentFilterKeys: Extract<
  FilterKey,
  | "minpayment"
  | "maxpayment"
  | "downamount"
  | "downpayment"
  | "downpercent"
  | "tradein"
  | "creditscore"
  | "term"
>[] = ["minpayment", "maxpayment", "downamount", "downpercent", "tradein", "creditscore", "term"];

export const dollarAmountFilterKeys: FilterKey[] = ["minprice", "maxprice"];
