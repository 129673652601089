import { getConfig } from "lib/config";

import * as REST from "./api-service";

import "./convert-click-offer-utility.ts";

const marketplaceApiUrl = getConfig()["MARKETPLACE_API_URL"];

const API_TIMEOUT = 30000;

export const getZipCode = async (coordinates, timeout = API_TIMEOUT) => {
  const lat = coordinates.latitude;
  const long = coordinates.longitude;
  const url = `${marketplaceApiUrl}/Geography/GetNearestPostalCodeFromCoordinate?lat=${lat}&lon=${long}`;
  const retVal = await REST.getData(url, timeout);
  return retVal;
};

export const getCoordinatesFromZip = async (zipCode, timeout = API_TIMEOUT) => {
  const url = `${marketplaceApiUrl}/Geography/GetCoordinateFromPostalCode?postalCode=${zipCode}`;
  const retVal = await REST.getData(url, timeout);
  if (retVal.data) {
    return retVal.data;
  } else {
    return Promise.reject(retVal.error?.message);
  }
};

export const unsubscribe = async (email, timeout = API_TIMEOUT) => {
  const encodedEmailAddress = encodeURIComponent(email);
  const url = `${marketplaceApiUrl}/Consumer/Unsubscribe?emailAddress=${encodedEmailAddress}`;
  const retVal = await REST.postData(url, {}, timeout);
  return retVal;
};
