let enableLogging = localStorage.getItem("enableLogging") ? true : false;

if (enableLogging) {
  console.log(
    "⚠️ konsole enableLogging is turned on in local storage. If you want to disable extra logging, run window.dtc.konsole.disableConsole()"
  );
}

export const konsole = {
  enable: () => {
    localStorage.setItem("enableLogging", "true");
    enableLogging = true;
  },
  disable: () => {
    localStorage.removeItem("enableLogging");
    enableLogging = false;
  },
  error: (...data) => {
    console.error(...data);
  },
  warn: (...data) => {
    if (enableLogging) {
      console.warn(...data);
    }
  },
  debug: (...data) => {
    if (enableLogging) {
      console.debug(...data);
    }
  },
  info: (...data) => {
    if (enableLogging) {
      console.info(...data);
    }
  },
  log: (...data) => {
    if (enableLogging) {
      console.log(...data);
    }
  },
  group: (...data) => {
    if (enableLogging) {
      console.group(...data);
    }
  },
  groupCollapsed: (...data) => {
    if (enableLogging) {
      console.groupCollapsed(...data);
    }
  },
  groupTable: (...data) => {
    if (enableLogging) {
      console.groupTable(...data);
    }
  },
  groupEnd: (...data) => {
    if (enableLogging) {
      console.groupEnd(...data);
    }
  },
};
export default konsole;

document.konsole = konsole;
