import { PropsWithChildren } from "react";

import { useTracking } from "react-tracking";

// Add pageTitle to the properties we can pass in to each child
const PageWrapper = ({ pageTitle, children }: PropsWithChildren<{ pageTitle: string }>) => {
  const { Track } = useTracking({ page: pageTitle }, {});
  return <Track>{children}</Track>;
};

export default PageWrapper;
