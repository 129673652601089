import propTypes from "prop-types";

/**
 * U Turn icon svg component
 *
 * @param {string} [props.className] - optional, default is empty string
 *
 */
const UTurnIcon = ({ className = "" }) => {
  return (
    <svg width="145" height="145" viewBox="0 0 145 145" fill="none" className={`${className}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.9 64.9628L80.0612 4.12412C75.8853 -0.0517784 69.1148 -0.0517981 64.9389 4.12411L4.10018 64.9628C-0.0757201 69.1387 -0.0757389 75.9092 4.10017 80.0851L64.9389 140.924C69.1148 145.1 75.8853 145.1 80.0612 140.924L140.9 80.0851C145.076 75.9092 145.076 69.1387 140.9 64.9628ZM70.4837 9.66895C71.5973 8.55538 73.4028 8.55538 74.5163 9.66895L135.355 70.5077C136.469 71.6213 136.469 73.4267 135.355 74.5403L74.5163 135.379C73.4028 136.493 71.5973 136.493 70.4837 135.379L9.64501 74.5403C8.53144 73.4267 8.53144 71.6212 9.64501 70.5077L70.4837 9.66895ZM82.2322 96.7614H96.7376V65.736C96.7376 59.4064 94.1906 53.3361 89.6568 48.8604C85.1229 44.3847 78.9738 41.8703 72.562 41.8703C66.1502 41.8703 60.001 44.3847 55.4672 48.8604C50.9334 53.3361 48.3864 59.4064 48.3864 65.736V75.2823H41.1337L55.639 91.9883L70.1444 75.2823H62.8917V65.736C62.8917 63.2042 63.9106 60.7761 65.7241 58.9858C67.5376 57.1955 69.9973 56.1897 72.562 56.1897C75.1267 56.1897 77.5864 57.1955 79.3999 58.9858C81.2134 60.7761 82.2322 63.2042 82.2322 65.736V96.7614Z"
        fill="#737373"
      />
      <defs>
        <rect width="144" height="144" fill="white" transform="translate(0.5 0.523926)" />
      </defs>
    </svg>
  );
};

UTurnIcon.propTypes = {
  className: propTypes.string,
};

export default UTurnIcon;
