import { captureMessage } from "@sentry/react";

export const getWithExpiry = (key: string) => {
  let itemStr: string | null = "";

  try {
    itemStr = localStorage.getItem(key);
  } catch (err) {
    captureMessage(`Getting localStore threw an error`, {
      level: "error",
      extra: {
        error: err,
      },
    });
  }

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

//ttl is in ms - defaulting at 24hrs
/* eslint-disable @typescript-eslint/no-explicit-any */
export const setWithExpiry = (key: string, value: any, ttl = 86400000) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (err) {
    captureMessage(`Setting localStore threw an error`, {
      level: "error",
      extra: {
        error: err,
      },
    });
  }
};
